import * as ko from "knockout";
import fetch from 'node-fetch';
import template from './PageFooter.html';
import { gitVersion } from '../git-version'
import { Link, LinkSet } from '../contracts';

class PageFooter {

  disclaimer: ko.Observable<string> = ko.observable<string>("");
  backendVersion: ko.Observable<string> = ko.observable<string>("");
  frontendVersion: ko.Observable<string> = ko.observable<string>(global.Logonme.Configuration.version);
  selectedLanguage: ko.PureComputed<string> = ko.pureComputed(() => global.Logonme.SelectedLanguage());
  footerLinks : ko.ObservableArray<Link> = ko.observableArray<Link>([]);
  footerColumns : ko.ObservableArray<LinkSet> = ko.observableArray<LinkSet>([]);

  constructor() {
    this.updateBackendVersion();
    this.updateFrontendVersion()

    this.updateStrings(this.selectedLanguage());
    this.selectedLanguage.subscribe((lang: string) => {
      this.updateStrings(lang)
    })
  }

  private updateStrings(lang: string) {
    this.disclaimer(global.Logonme.LocalizationManager.getText('footer-disclaimer', lang));

    this.footerLinks([]);
    var columns = 10;
    for(var i = 1; i <= columns; i++){
        var linkText = global.Logonme.LocalizationManager.getText(`footer-link${i}-text`, lang);
        var linkUri = global.Logonme.LocalizationManager.getText(`footer-link${i}-src`, lang);
        if (linkText != null && linkText != "" && linkUri != null && linkUri != "") {
          this.footerLinks.push(new Link(linkUri, linkText, null))
        }
    }

    this.footerColumns([]);
    var columns = 10;
    for(var i = 1; i <= columns; i++){
        var headerText = global.Logonme.LocalizationManager.getText(`footer-column${i}-header`, lang);
        var headerUri = global.Logonme.LocalizationManager.getText(`footer-column${i}-src`, lang);
        var headerImageSrc = global.Logonme.LocalizationManager.getText(`footer-column${i}-image-src`, lang);

        var imageSrc = `${global.Logonme.Configuration.baseUrl}/ls/${global.Logonme.Configuration.tenant}/asset/${headerImageSrc}`;
        var header = (headerText != null && headerText != "") ? new Link(headerUri, headerText, imageSrc) : null;

        if (header != null) {
          var rows = 5;
          var links = new Array<Link>()
          for(var j = 1; j <= rows; j++){
            var linkText = global.Logonme.LocalizationManager.getText(`footer-column${i}-row${j}-text`, lang);
            var linkUri = global.Logonme.LocalizationManager.getText(`footer-column${i}-row${j}-src`, lang);
            if (linkText != null && linkText != "") {
              var link = new Link(linkUri, linkText, null);
              links.push(link);
            }
          }
          this.footerColumns.push(new LinkSet(header, links));
      }
    }
  }

  private async updateFrontendVersion() {
    var version = `${gitVersion.tag}-${gitVersion.branch}-${gitVersion.commit}`
    this.frontendVersion(version)
  }

  private async updateBackendVersion() {
    var uri = `${global.Logonme.Configuration.baseUrl}/ls/${global.Logonme.Configuration.tenant}/about/version`;
    var response = await fetch(uri);
    var version = await response.text();
    this.backendVersion(version);
  }
}

export default { viewModel: PageFooter, template: template };
