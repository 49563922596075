import * as ko from 'knockout';
import fetch from 'node-fetch';

import { LogoutResponse, User, AuthState, App } from '../contracts';
import template from './User.html';
import { LogonmeConfiguration, ViewId } from '../configuration';
import { AuthenticatedLocalizationData, AuthenticatedLocalizationManager } from '../localization';

class Home {

  lm: AuthenticatedLocalizationManager | undefined;
  apps: ko.ObservableArray<App[]> = ko.observableArray<App[]>([]);

  displayName: ko.Observable<string> = ko.observable<string>("");
  description: ko.Observable<string> = ko.observable<string>("");
  userName: ko.Observable<string> = ko.observable<string>("");
  userId: ko.Observable<string> = ko.observable<string>("");
  emailAddress: ko.Observable<string> = ko.observable<string>("");
  mobilePhone: ko.Observable<string> = ko.observable<string>("");

  authenticatedWith: ko.Observable<string> = ko.observable<string>("")

  logoutButton: ko.Observable<string> = ko.observable<string>("");
  selectedLanguage: ko.PureComputed<string> = ko.pureComputed(() => global.Logonme.SelectedLanguage());
  activateView: ko.PureComputed<boolean> = ko.pureComputed(() => global.Logonme.ActiveView() == ViewId.Home);

  constructor() {
    this.selectedLanguage.subscribe((lang: string) => {
      this.updateStrings(lang)
    })

    this.activateView.subscribe((activate: boolean) => {
      if (activate) {
        console.log("Home:activate");
        this.initialize();
      }
    });
  }

  private async initialize() {
    console.log("Home:initialize");
    await this.getLocalizationModel(global.Logonme.Configuration)
    await this.doGetCurrentUser();
    this.updateStrings(this.selectedLanguage())
  }

  private updateStrings(lang: string) {
    this.logoutButton(global.Logonme.LocalizationManager.getText("logout-button", lang));
  }

  private async getCurrentUser(): Promise<User> {
    var uri = `${global.Logonme.Configuration.baseUrl}/ls/${global.Logonme.Configuration.tenant}/whoami`;
    var response = await fetch(uri);
    var userResponse = await response.json<User>();
    return userResponse;
  }

  private async getApplications(): Promise<App[]> {
    var uri = `${global.Logonme.Configuration.baseUrl}/ls/${global.Logonme.Configuration.tenant}/applications/my`;
    var response = await fetch(uri);
    var applicationsResponse = await response.json<App[]>();
    return applicationsResponse;
  }

  async doSelectApplication(app: App) {
    var lang = this.selectedLanguage();
    var linkUri = this.lm?.getAppProperty(app, "linkUri", "about:blank", lang, "default")
    window.open(linkUri, '_blank');
  }

  private async getLocalizationModel(config: LogonmeConfiguration) {
    var uri = `${config.baseUrl}/ls/${config.tenant}/applications/i18n`;
    var response = await fetch(uri);
    var localizationModel = await response.json<AuthenticatedLocalizationData>();
    this.localizationData = localizationModel;
    this.lm = new AuthenticatedLocalizationManager(localizationModel);
  }

  private async logout(): Promise<LogoutResponse> {
    var uri = `${global.Logonme.Configuration.baseUrl}/ls/${global.Logonme.Configuration.tenant}/forms/logout`;
    var response = await fetch(uri);
    var logoutResponse = await response.json<LogoutResponse>();
    return logoutResponse;
  }

  async doLogout() {
    await this.logout().then((response: LogoutResponse) => {
      global.Logonme.AuthState(response.authState);
      global.Logonme.SelectedMethod(null);
    });
  }

  async doGetCurrentUser() {
    console.log("doGetCurrentUser")
    var apps = await this.getApplications();
    this.apps(apps);

    var user = await this.getCurrentUser();
    this.displayName(user.displayName);
    this.description(user.description);
    this.userName(user.username);
    this.userId(user.userId);
    this.emailAddress(user.emailAddress);
    this.mobilePhone(user.mobilePhone);
    
    this.authenticatedWith(user.authenticatedWith);
  }
}

export default { viewModel: Home, template: template };
