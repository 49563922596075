import * as ko from "knockout";

import { AuthMethod, AuthState } from "./contracts";
import { ViewId } from "./configuration";

class AuthViewModel {

    showCookieConsent: ko.PureComputed<boolean> = ko.pureComputed(() => !global.Logonme.HasConsent());
    showAuthPanel: ko.PureComputed<boolean> = ko.pureComputed(() => global.Logonme.HasConsent() && global.Logonme.ActiveView() == ViewId.Auth);
    showMethodListPanel: ko.PureComputed<boolean> = ko.pureComputed(() => global.Logonme.HasConsent() && global.Logonme.ActiveView() == ViewId.Methods);
    showUserPanel: ko.PureComputed<boolean> = ko.pureComputed(() => global.Logonme.HasConsent() && global.Logonme.ActiveView() == ViewId.Home);

    selectedMethod: ko.PureComputed<AuthMethod | null> = ko.pureComputed(() => global.Logonme.SelectedMethod());

    constructor() {

        console.log("AuthViewModel:constructor");

        this.selectedMethod.subscribe((method: AuthMethod | null) => {
            if (method == null) {
                var authState = global.Logonme.AuthState();
                if (authState == AuthState.PathCancelled) {
                    global.Logonme.LocalStorageManager.removeItem("last-login-method");
                    console.log(`last-login-cleared for ${authState}`);
                }

            } else {
                if (method.id != null) {
                    global.Logonme.LocalStorageManager.setItem("last-login-method", method);
                    console.log(`last-login-set for ${method.id}`);
                }
            }
        })

        global.Logonme.ViewConstructedCount(global.Logonme.ViewConstructedCount() + 1);
    }

    doChangeLanguage(language: string) {
        console.log(language);
        global.Logonme.SelectedLanguage(language);
    }

    doAfterRender(elements: any, data: any) {
        console.log("doAfterRender");
    }

}

export { AuthViewModel };
