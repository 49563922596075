import * as ko from "knockout";
import template from './PageHeader.html';
import { Country } from "localization";


class PageHeader {

  private defaultLang: Country = global.Logonme.LocalizationManager.getLocalizations()[1]
  languages: ko.ObservableArray<Country> = ko.observableArray()
  selectedCountry: ko.Observable<Country> = ko.observable<Country>(this.defaultLang);
  selectLanguageText: ko.Observable<string> = ko.observable<string>("");
  selectedLangage: ko.PureComputed<string> = ko.pureComputed(() => global.Logonme.SelectedLanguage());

  constructor() {

    this.updateStrings(this.selectedLangage());
    this.selectedLangage.subscribe((lang :string) => {
      this.updateStrings(lang)
    })

    var localizations = global.Logonme.LocalizationManager.getLocalizations()
    for (var i = 0; i < localizations.length; i++) {
      this.languages.push(localizations[i]);
    }
    

    this.selectedCountry.subscribe((val: Country) => {
      global.Logonme.SelectedLanguage(val.langCode);
    });
  }

  private updateStrings(lang: string) {
    this.selectLanguageText(global.Logonme.LocalizationManager.getText('header-select-language', lang));
  }
}

export default { viewModel: PageHeader, template: template };
