import * as ko from 'knockout';
import fetch from 'node-fetch';
import PageHeader from './components/PageHeader';
import PagePuff from './components/PagePuff';
import PageFooter from './components/PageFooter';
import FormsAttribute from './components/FormsAttribute';
import Auth from './components/Auth';
import MethodList from './components/MethodList';
import AuthLink from './components/AuthLink';
import User from './components/User';
import Application from './components/Application';
import { AuthViewModel } from './models'
import { LocalizationManager, LocalizationData } from './localization'
import { LogonmeConfiguration, LogonmeGlobalData, ViewId } from './configuration'
import { AuthMethod } from './contracts';
import ProgressBar from './components/ProgressBar';
import CookieConsent from './components/CookieConsent';
import FooterColumn from './components/FooterColumn';
import ContentHeader from './components/ContentHeader';

ko.components.register('page-header', PageHeader);
ko.components.register('page-puff', PagePuff);
ko.components.register('page-footer', PageFooter);
ko.components.register('page-footer-column', FooterColumn);
ko.components.register('forms-attribute', FormsAttribute);
ko.components.register('auth', Auth);
ko.components.register('method-list', MethodList);
ko.components.register('auth-link', AuthLink);
ko.components.register('user', User);
ko.components.register('application', Application);
ko.components.register('progress-bar', ProgressBar);
ko.components.register('cookie-consent', CookieConsent);
ko.components.register('content-header', ContentHeader);

var config = new LogonmeConfiguration(window.location.href);
var localizationData = await getLocalizationModel(config);
console.log(localizationData.localizations);

var lm = new LocalizationManager(localizationData);
global.Logonme = new LogonmeGlobalData(lm, window.location.href);

global.Logonme.ViewConstructedCount.subscribe((count: number) => {
    console.log(`Views ready ${count}`);
    if (count < 3)
        return;

    var lastLogin = global.Logonme.LocalStorageManager.getItem<AuthMethod>("last-login-method", null);
    var startupMethod = global.Logonme.Configuration.startupMethod ?? lastLogin?.id;
    if (startupMethod) {
        global.Logonme.Configuration.startupMethod = startupMethod;
    }
    global.Logonme.SelectedMethod(null);
    global.Logonme.ActiveView(ViewId.Methods);
});

var pageTitle = lm.getText("page-title", "default");
document.title = pageTitle

ko.applyBindings(new AuthViewModel(), document.getElementById('app'));

loadCssFile(`${config.baseUrl}/ls/${config.tenant}/asset/branding.css`);
loadCssFile(`${config.baseUrl}/ls/${config.tenant}/asset/branding-fonts.css`);

async function getLocalizationModel(config: LogonmeConfiguration): Promise<LocalizationData> {
    var uri = `${config.baseUrl}/ls/${config.tenant}/localization/i18n?version=${config.version}`;
    var response = await fetch(uri);
    var localizationModel = await response.json<LocalizationData>();
    return localizationModel;
}

function loadCssFile(filename: string) {
    var file = document.createElement("link")
    file.setAttribute("rel", "stylesheet")
    file.setAttribute("type", "text/css")
    file.setAttribute("href", filename)
    document.getElementsByTagName("head")[0].appendChild(file)
}
