import * as ko from "knockout";
import { AuthAttribute, AuthMethod, AuthRequest, NullAuthMethod } from '../contracts';
import template from './FormsAttribute.html';


class FormsAttribute {

  type: ko.Observable<string> = ko.observable<string>("text");
  label: ko.Observable<string> = ko.observable<string>("");
  name: ko.Observable<string> = ko.observable<string>("");
  placeHolder: ko.Observable<string> = ko.observable<string>("");
  value: ko.Observable<string | null> = ko.observable<string>(null);
  sortOrder: ko.Observable<number> = ko.observable<number>(0);
  imageSrc: ko.Observable<string | null> = ko.observable<string>(null);
  imageAlt: ko.Observable<string | null> = ko.observable<string>(null);
  rememberMe: ko.Observable<string> = ko.observable<string>("");
  inputEnabled: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => !this.persisted());
  persisted: ko.Observable<boolean> = ko.observable<boolean>(false);
  validationError: ko.Observable<string | null> = ko.observable<string>(null);
  hasValidationError: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => this.validationError() != null)
  isVisible: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => this.type() === "text" || this.type() === "password");
  hasFocus: ko.Observable<boolean> = ko.observable<boolean>(false);
  message: ko.Observable<string> = ko.observable<string>("");

  selectedMethod: ko.PureComputed<AuthMethod> = ko.pureComputed(() => global.Logonme.SelectedMethod() ?? new NullAuthMethod());
  selectedLanguage: ko.PureComputed<string> = ko.pureComputed(() => global.Logonme.SelectedLanguage());
  canBePersisted: ko.Observable<boolean> = ko.observable(false);

  constructor(params: { attribute: AuthAttribute }) {
    var attribute = params.attribute;

    this.hasFocus(params.attribute.hasFocus);
    this.value(attribute.value);
    this.value.subscribe((value: string | null) => {
      attribute.value = value;
    });


    this.persisted(attribute.persisted);
    this.persisted.subscribe((value: boolean) => {
      attribute.persisted = value;
    })

    this.updateStrings(this.selectedLanguage(), this.selectedMethod(), attribute);

    this.selectedLanguage.subscribe((lang: string) => {
      this.updateStrings(lang, this.selectedMethod(), attribute)
    })
  }

  private focusNextInput(buttonElementId: string) {
    var focusInput = document.activeElement;
    if (focusInput?.tagName.toLowerCase() === "input") {
      var inputs = [];
      var all = document.getElementsByTagName("input");;
      for (var i = 0; i < all.length; i++) {
        if (all[i].getAttribute("type") === "text" || all[i].getAttribute("type") === "password") {
          inputs.push(all[i]);
        }
      }
      focusInput = document.activeElement;
      for (var i = 0; i < inputs.length; i++) {
        if (inputs[i] === focusInput) {
          var next = inputs[i + 1];
          if (next && next.focus) {
            next.focus();
          } else {
            var element = document.getElementById(buttonElementId);
            element?.focus();
          }
          break;
        }
      }
    }
  }

  private handleKeyPressed(data: any, event: KeyboardEvent) {
    switch (event.code) {
      case "Enter":
      case "NumpadEnter":
        this.focusNextInput("auth.submitButton")
        break;
    }
    return true;
  }

  private updateStrings(lang: string, method: AuthMethod, attribute: AuthAttribute) {

    this.rememberMe(global.Logonme.LocalizationManager.getText("auth-form-remember-me", lang));
    var canBePersisted = (attribute.name == AuthRequest.AttributePwdUsername || attribute.name == AuthRequest.AttributeBankIdPnr || attribute.name == AuthRequest.AttributeFido2Username);
    this.canBePersisted(canBePersisted);

    var isImage = (attribute.name == AuthRequest.AttributeBankIdChallengeQr || attribute.name == AuthRequest.AttributeOtpEnrollmentQrCode || attribute.name == AuthRequest.AttributeCaptchaImage);
    if (isImage) {
      this.imageSrc(attribute.value);
      this.imageAlt(attribute.name);
    }
    var isMessage = (attribute.type == "message" || attribute.name == AuthRequest.AttributeChallengeMessage);
    if (isMessage) {
      if (attribute.value != null) {
        this.message = global.Logonme.LocalizationManager.getMessage(method, attribute.value ?? "", null, lang);
      }
    }
    var aid = attribute.name ?? "";
    var errorId = attribute.validationError ?? ""
    this.type(global.Logonme.LocalizationManager.getPropertyX(method, ['attributes', aid, 'inputType'], null, null));
    this.placeHolder(global.Logonme.LocalizationManager.getPropertyX(method, ['attributes', aid, 'placeHolder'], null, lang));
    this.label(global.Logonme.LocalizationManager.getPropertyX(method, ['attributes', aid, 'text'], null, lang));
    this.sortOrder(global.Logonme.LocalizationManager.getPropertyX(method, ['attributes', aid, 'sortOrder'], null, null));
    this.validationError(global.Logonme.LocalizationManager.getMessage(method, errorId, null, lang));
  }
}

export default { viewModel: FormsAttribute, template: template };
