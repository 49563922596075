
import { App, AuthMethod } from './contracts';

class LocalizedString {
    [key: string]: string;
}

class LocalizedUri {
    uri: LocalizedString | undefined;
    text: LocalizedString | undefined;
}

class LocalizedMethodInfo {
    description: LocalizedString | undefined;
    link: LocalizedUri | undefined;
}

class LocalizedButton extends LocalizedString {
}

class LocalizedAttribute {
    name: string | undefined;
    sortOder: number = 0;
    inputType: string = "text";
    text: LocalizedString | undefined;
    placeHolder: LocalizedString | undefined;
}

class LocalizedMethod {
    id: string | undefined;
    path: string | undefined;
    displayName: LocalizedString | undefined;
    title: LocalizedString | undefined;
    subTitle: LocalizedString | undefined;
    sortOrder: number | undefined;
    imageUrl: LocalizedUri | undefined;
    info: LocalizedMethodInfo | undefined;
    buttons: { [name: string]: LocalizedButton } = {};
    attributes: { [name: string]: LocalizedAttribute } = {};
    messages: { [name: string]: LocalizedString } = {};
}

class LocalizedApplication {
    id: string | undefined;
    linkUri: string | undefined;
    linkName: LocalizedString | undefined;
    linkDescription: LocalizedString | undefined;
    linkTooltip: LocalizedString | undefined;
    sortOrder: number | undefined;
    iconUrl: LocalizedUri | undefined;
}


class SectionAuthentication {
    methods: { [name: string]: LocalizedMethod } = {};
    messages: { [name: string]: LocalizedString } = {};
}

class SectionPortalApplications {
    applications: { [name: string]: LocalizedApplication } = {};
}

class AuthenticatedLocalizationData {
    applicationPortal: SectionPortalApplications | undefined;
}

class LocalizationData {
    authentication: SectionAuthentication | undefined;
    texts: { [name: string]: LocalizedString } = {};
    localizations: Country[] = [];
}

class Country {
    displayName: string = '';
    langCode: string = '';
    flagImageUrl: string |undefined
}

class AuthenticatedLocalizationManager {

    data: AuthenticatedLocalizationData;
    logErrors: boolean = false;

    constructor(data: AuthenticatedLocalizationData) {
        this.data = data;
    }

    public getAppProperty(app: App, property: string, defaultValue: string | null, lang: string, defaultLang: string): string {
        var m = this.getApplication(app);
        if (m) {
            try {
                var value = m[property][lang];
                if (!value && !defaultValue) {
                    value = m[property][defaultLang];
                }
                return value;
            } catch {
                if (this.logErrors) console.log(`Failed to get property ${property} on app ${app}`);
            }
        }
        return defaultValue ?? "";
    }

    public getApplication(app: App): any {
        var appId = app.id ?? "";
        return this.data.applicationPortal?.applications[appId];
    }
}



class LocalizationManager {

    data: LocalizationData;
    logErrors: boolean = false;

    constructor(localizationData: LocalizationData) {
        this.data = localizationData;
    }

    public getLocalizations() : Country[]  {
        return this.data.localizations;
    }

    public getText(id: string, lang: string, defaultValue: string | null): string {
        try {
            return this.data.texts[id][lang];
        } catch {
            if (this.logErrors) console.log(`Failed to get text ${id}`);
        }
        return defaultValue ?? "";
    }

    public getPropertyX(method: AuthMethod, path: string[], defaultValue: string | null, lang: string | null): any | null {
        var m = this.getMethod(method);
        if (m) {

            try {
                var p1 = path[0];
                var p2 = path[1];
                var p3 = path[2];

                if (p1 && p2 && p3) {
                    var property = m[p1][p2][p3];
                    var propertyValue = lang ? property[lang] : (property ?? null);
                }
                else if (p1 && p2) {
                    var property = m[p1][p2];
                    var propertyValue = lang ? property[lang] : (property ?? null);
                }
                else if (p1) {
                    var property = m[p1];
                    var propertyValue = lang ? property[lang] : (property ?? null);
                }

                if (propertyValue)
                    return propertyValue;

            } catch {
                if (this.logErrors) console.log(`Failed to get property ${path} on method ${m}`);
            }
        }
        return defaultValue;
    }

    public getMessage(method: AuthMethod, id: string, defaultValue: string|null, lang: string) {
        var m = this.getMethod(method);
        if (m) {
            try {
                var validationError = m.messages[id] ? m.messages[id][lang] : null;
                if (!validationError)
                    validationError = this.data.authentication?.messages[id] ? this.data.authentication?.messages[id][lang] : null;
                if (validationError)
                    return validationError;

            } catch {
                if (this.logErrors) console.log(`Failed to get message ${id} on method ${m}`);
            }
        }
        return defaultValue ?? id;
    }


    public getMethod(method: AuthMethod): any {
        var methodId = method.id ?? "";
        return this.data.authentication?.methods[methodId];
    }
}

export { LocalizedString, LocalizedUri, LocalizedMethodInfo, LocalizedButton, LocalizedAttribute, LocalizedMethod, SectionAuthentication, Country, LocalizationData, AuthenticatedLocalizationData, AuthenticatedLocalizationManager, LocalizationManager };


