import * as ko from "knockout";
import template from './CookieConsent.html';
import { getCookie, setCookie } from 'typescript-cookie'

class CookieConsent {

  consentMessageTitle: ko.Observable<string> = ko.observable<string>("");
  consentMessage: ko.Observable<string> = ko.observable<string>("")
  consentDetailsLinkUri: ko.Observable<string> = ko.observable<string>("")
  consentDetailsLinkText: ko.Observable<string> = ko.observable<string>("")
  acceptButton: ko.Observable<string> = ko.observable<string>("")
  rejectButton: ko.Observable<string> = ko.observable<string>("")
  rejectLinkUri: ko.Observable<string> = ko.observable<string>("")
  selectedLanguage: ko.PureComputed<string> = ko.pureComputed(() => global.Logonme.SelectedLanguage());

  constructor() {
    console.log("CookieConsent:constructor");

    this.updateStrings(this.selectedLanguage());
    this.selectedLanguage.subscribe((lang: string) => {
      this.updateStrings(lang)
    })
  }

  async doAccept() {
    console.log("doAccept")
    var tenant = global.Logonme.Configuration.tenant;
    setCookie(".logonme-cookie-consent", tenant, { path: '/', expires: 365 });
    global.Logonme.HasConsent(true);
  }

  async doReject() {
    console.log("doReject")
    var rejectUri = this.rejectLinkUri();
    window.location.href = rejectUri;
  }

  private updateStrings(lang: string) {
    this.consentMessageTitle(global.Logonme.LocalizationManager.getText('cookie-consent-title', lang));
    this.consentMessage(global.Logonme.LocalizationManager.getText("cookie-consent-text", lang));
    this.consentDetailsLinkUri(global.Logonme.LocalizationManager.getText("cookie-consent-details-link-uri", lang));
    this.consentDetailsLinkText(global.Logonme.LocalizationManager.getText("cookie-consent-details-link-text", lang));
    this.rejectLinkUri(global.Logonme.LocalizationManager.getText("cookie-consent-reject-link-uri", lang));
    this.acceptButton(global.Logonme.LocalizationManager.getText("cookie-consent-accept-button", lang));
    this.rejectButton(global.Logonme.LocalizationManager.getText("cookie-consent-reject-button", lang));
  }
}

export default { viewModel: CookieConsent, template: template };
