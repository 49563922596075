import * as ko from "knockout";
import template from './ProgressBar.html';


class ProgressBar {
  inProgress: ko.Observable<boolean> = ko.observable<boolean>(false);

  constructor() {
    this.inProgress(true)
  }
}

export default { viewModel: ProgressBar, template: template };
