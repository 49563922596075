import * as ko from 'knockout';
import fetch from 'node-fetch';
import { Parameters, ReCaptcha } from 'grecaptcha';

import { Fido2Manager } from '../fido';
import { AuthAttribute, AuthMethod, NullAuthMethod, AuthRequest, AuthResponse, AuthState, UserMessage, UserMessageType } from '../contracts';
import template from './Auth.html';
import { ViewId } from '../configuration';

class Auth {

  reCaptcha: ReCaptcha  | null;
  title: ko.Observable<string> = ko.observable<string>("");
  subTitle: ko.Observable<string> = ko.observable<string>("");
  message: ko.Observable<string | null> = ko.observable<string>(null);
  messageType: ko.Observable<string> = ko.observable("");
  hasMessage: ko.PureComputed<boolean> = ko.pureComputed(() => this.message() != null && this.message() != '');
  infoTitle: ko.Observable<string> = ko.observable<string>("");
  infoDescription: ko.Observable<string> = ko.observable<string>("");
  infoLink1Text: ko.Observable<string> = ko.observable<string>("");
  infoLink1Uri: ko.Observable<string> = ko.observable<string>("");
  infoLink2Text: ko.Observable<string> = ko.observable<string>("");
  infoLink2Uri: ko.Observable<string> = ko.observable<string>("");

  backLink: ko.Observable<string> = ko.observable<string>("");
  cancelButton: ko.Observable<string> = ko.observable<string>("");
  submitButton: ko.Observable<string> = ko.observable<string>("");
  challengeAttributes: ko.ObservableArray<AuthAttribute> = ko.observableArray<AuthAttribute>([]);
  selectedMethod: ko.PureComputed<AuthMethod> = ko.pureComputed(() => global.Logonme.SelectedMethod() ?? new NullAuthMethod());
  selectedMethodAndPath: ko.PureComputed<string> = ko.pureComputed(() => {
    var method = this.selectedMethod();
    return method.id + '@' + method.path;
  });
  selectedLanguage: ko.PureComputed<string> = ko.pureComputed(() => global.Logonme.SelectedLanguage());
  activateView: ko.PureComputed<boolean> = ko.pureComputed(() => global.Logonme.ActiveView() == ViewId.Auth);
  inProgress: ko.Observable<boolean> = ko.observable<boolean>(false);
  cancelRequested: ko.Observable<boolean> = ko.observable<boolean>(false);

  showCancelButton: ko.PureComputed<boolean> = ko.pureComputed(() => this.inProgress())
  showSubmitButton: ko.PureComputed<boolean> = ko.pureComputed(() => !this.inProgress())
  showMethodInfo: ko.Observable<boolean> = ko.observable<boolean>(false);

  constructor() {

    console.log("Auth:constructor");
    this.updateStrings(this.selectedLanguage(), this.selectedMethod())
    this.selectedLanguage.subscribe((lang: string) => {
      this.updateStrings(lang, this.selectedMethod())
    })

    this.activateView.subscribe((activate: boolean) => {
      if (activate) {
        console.log("Auth:activate");
        this.message(null);
        this.challengeAttributes([]);
        this.cancelRequested(false);
        this.doAuthenticate();
      }
    });

    global.Logonme.ViewConstructedCount(global.Logonme.ViewConstructedCount() + 1);
    this.reCaptcha = null;
  }

  private updateStrings(lang: string, method: AuthMethod) {

    this.title(global.Logonme.LocalizationManager.getPropertyX(method, ['title'], null, lang));
    this.subTitle(global.Logonme.LocalizationManager.getPropertyX(method, ['subTitle'], null, lang));
    this.backLink(global.Logonme.LocalizationManager.getPropertyX(method, ['buttons', 'back'], null, lang));
    this.cancelButton(global.Logonme.LocalizationManager.getPropertyX(method, ['buttons', 'cancel'], null, lang));
    this.submitButton(global.Logonme.LocalizationManager.getPropertyX(method, ['buttons', 'submit'], null, lang));

    var infoTitle = global.Logonme.LocalizationManager.getPropertyX(method, ['info', 'title'], null, lang);
    var infoDescription = global.Logonme.LocalizationManager.getPropertyX(method, ['info', 'description'], null, lang);
    var showInfo = infoTitle || infoDescription;
    this.showMethodInfo(showInfo)

    this.infoTitle(infoTitle);
    this.infoDescription(infoDescription);
    this.infoLink1Text(global.Logonme.LocalizationManager.getPropertyX(method, ['info', 'link1', 'text'], null, lang));
    this.infoLink1Uri(global.Logonme.LocalizationManager.getPropertyX(method, ['info', 'link1', 'uri'], null, lang));
    this.infoLink2Text(global.Logonme.LocalizationManager.getPropertyX(method, ['info', 'link2', 'text'], null, lang));
    this.infoLink2Uri(global.Logonme.LocalizationManager.getPropertyX(method, ['info', 'link2', 'uri'], null, lang));
  }

  private updateAttributeAndMessageStrings(lang: string, method: AuthMethod, message: UserMessage) {

    var messageId = message.message ?? "";
    var messageValue = global.Logonme.LocalizationManager.getMessage(method, messageId, null, lang)

    if (messageValue != null && messageValue != '') {
      this.message(messageValue);
      switch (message.messageType) {
        case UserMessageType.Information:
          this.messageType("user-message-information");
          break;
        case UserMessageType.Warning:
          this.messageType("user-message-warning");
          break;
        case UserMessageType.Error:
          this.messageType("user-message-error");
          break;
        case UserMessageType.Success:
          this.messageType("user-message-success");
          break;
        default:
          break;
      }
    } else {
      this.message(null);
    }
  }

  private async authenticate(request: AuthRequest): Promise<AuthResponse> {

    var uri = `${global.Logonme.Configuration.baseUrl}/ls/${global.Logonme.Configuration.tenant}/forms/auth`;
    var response = await fetch(uri,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });

    var authResponse = await response.json<AuthResponse>();
    return authResponse;
  }

  async doCancel() {
    this.cancelRequested(true);
    // this.inProgress(true);
    // await this.callAuthenticate(true);
    // this.inProgress(false);
    //global.Logonme.SelectedMethod(null);
  }

  async doAuthenticate() {
    this.inProgress(true);
    await this.callAuthenticate();
    this.inProgress(false);
  }

  private convertAttributes(method: AuthMethod, challengeAttributes: AuthAttribute[]): AuthAttribute[] {

    function shouldHaveFocus(method: AuthMethod, attribute: AuthAttribute): boolean {
      if (attribute.persisted) {
        return false;
      }
      var aid = attribute.name ?? "";
      var attrType = global.Logonme.LocalizationManager.getPropertyX(method, ['attributes', aid, 'inputType'], null, null);
      if (attrType == "text" || attrType == "password") {
        return true;
      }
      return false;
    }

    function compareSortOrder(method: AuthMethod, attributeA: AuthAttribute, attributeB: AuthAttribute): number {
      var aidA = attributeA.name ?? "";
      var sortOrderA = Number.parseInt(global.Logonme.LocalizationManager.getPropertyX(method, ['attributes', aidA, 'sortOrder'], "0",  null));
      var aidB = attributeB.name ?? "";
      var sortOrderB = Number.parseInt(global.Logonme.LocalizationManager.getPropertyX(method, ['attributes', aidB, 'sortOrder'], "0",  null));
      return sortOrderA < sortOrderB ? -1 : 1;
    }

    var sorted = challengeAttributes.sort((a, b) => compareSortOrder(method, a, b));
    var focusAttribute = sorted.find((attr: AuthAttribute) => shouldHaveFocus(method, attr));
    if (focusAttribute)
      focusAttribute.hasFocus = true;

    return sorted;
  }

  private async callAuthenticate() {
    console.log("callAuthenticate")

    var method = this.selectedMethod();
    var lang = this.selectedLanguage();
    var route = this.selectedMethodAndPath();
    if (route == null || route === 'null@null')
      throw new Error(`Route not set`);

    var request = new AuthRequest;
    request.challengeAttributes = this.challengeAttributes();
    request.selectedMethodAndPath = route;
    request.cancel = this.cancelRequested();
    request.startupParams = global.Logonme.Configuration.startupParams;

    var response = await this.authenticate(request);
    console.log(response);

    var sorted = this.convertAttributes(method, response.challengeAttributes ?? []);
    this.challengeAttributes(sorted);
    global.Logonme.AuthState(response.authState);

    this.updateStrings(lang, method)
    if (response.message)
      this.updateAttributeAndMessageStrings(lang, method, response.message)

    var activeReCaptcha = await this.activeReCaptcha(response);
    if (activeReCaptcha) {
      console.log("re-captcha activated");
    }

    var autoPostResult = await this.handleFidoCommands(response);
    if (autoPostResult) {
      console.log("auto-posting result");
      this.callAuthenticate()
    }

    this.inProgress(response.pollServer)

    if (response.pollServer) {
      setTimeout(() => {
        console.log("polling server");
        this.callAuthenticate()
      }, 1000);
    } 

    if (response.launchExternal && response.launchUri) {
      global.Logonme.LocalStorageManager.removeItem("last-login-method");

      var redirectUri = response.launchUri; // relative
      console.log(`auth: re-directing to uri ${redirectUri}`)
      window.location.href = redirectUri;
    }
  }

  private async handleFidoCommands(response: AuthResponse): Promise<boolean> {
    if (response.challengeAttributes == null || response.authState != AuthState.PathInProgress)
      return false;

    try {
      var fido2 = new Fido2Manager();
      var attrAuthChallenge = response.challengeAttributes.find(a => a.name == AuthRequest.AttributeAuthChallenge);
      var attrAuthResponse = response.challengeAttributes.find(a => a.name == AuthRequest.AttributeAuthResponse);
      if (attrAuthChallenge != null && attrAuthResponse != null) {
        var fidoChallenge = attrAuthChallenge.value;
        var fidoResponse: string = await fido2.handleFidoLogin(attrAuthChallenge.value);
        console.log(`FIDO2 Authentication: ${fidoChallenge} => ${fidoResponse}`);
        attrAuthResponse.value = fidoResponse;
        return true;
      }

      var attrRegChallenge = response.challengeAttributes.find(a => a.name == AuthRequest.AttributeRegistrationChallenge);
      var attrRegResponse = response.challengeAttributes.find(a => a.name == AuthRequest.AttributeRegistrationResponse);
      if (attrRegChallenge != null && attrRegResponse != null) {
        var fidoChallenge = attrRegChallenge.value;
        var fidoResponse: string = await fido2.handleFidoAttestation(fidoChallenge);
        console.log(`FIDO2 Registration: ${fidoChallenge} => ${fidoResponse}`);
        attrRegResponse.value = fidoResponse;
        return true;
      }

      return false;

    } catch (exception: any) {
      alert(`${exception}`)
      return false;
    }
  }

  private loadScriptAsync(url: string) :Promise<any> {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
    });
  }
  
  private async activeReCaptcha(response: AuthResponse): Promise<boolean> {
    if (response.challengeAttributes == null || response.authState != AuthState.PathInProgress)
      return false;
 
    var siteKey = response.challengeAttributes.find(a => a.name == AuthRequest.AttributeReCaptchaSiteKey)?.value;
    if (siteKey == null)
      return false;

    if (this.reCaptcha == null) {
      var scriptUrl = response.challengeAttributes.find(a => a.name == AuthRequest.AttributeReCaptchaScriptUrl)?.value ?? null;
      if (scriptUrl == null)
        return false;

      await this.loadScriptAsync(scriptUrl);
      this.reCaptcha = await this.waitForReCaptchaIsReady();
      console.log(`Re-captcha successfully loaded from ${scriptUrl}`);
    }

    var attrReCaptchaResponse = response.challengeAttributes.find(a => a.name == AuthRequest.AttributeReCaptchaResponse);
    if (attrReCaptchaResponse == null) 
      return false;

    var action = response.challengeAttributes.find(a => a.name == AuthRequest.AttributeReCaptchaAction)?.value;
    if (action == null)
      return false;

    var token = await this.reCaptcha?.execute(siteKey, { action: action });
    attrReCaptchaResponse.value = token;
    return true;
  }

  private waitForReCaptchaIsReady(): Promise<ReCaptcha> {
    return new Promise((resolve) => {
      grecaptcha.enterprise.ready(() => {
        var reCaptcha:ReCaptcha = grecaptcha.enterprise;
        resolve(reCaptcha);
      })
    });
  }
}



export default { viewModel: Auth, template: template };
