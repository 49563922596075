import * as ko from 'knockout';

import { App } from '../contracts';
import template from './Application.html';
import { AuthenticatedLocalizationManager, AuthenticatedLocalizationData } from '../localization';

class Application {

  app: App;
  lm: AuthenticatedLocalizationManager;
  id: ko.Observable<string> = ko.observable<string>("");
  name: ko.Observable<string> = ko.observable<string>("");
  description: ko.Observable<string> = ko.observable<string>("");
  tooltip: ko.Observable<string> = ko.observable<string>("");
  linkUri: ko.Observable<string> = ko.observable<string>("");
  iconUri: ko.Observable<string> = ko.observable<string>("");
  iconAlt: ko.Observable<string> = ko.observable<string>("icon");
  logErrors: boolean = false;
  selectedLanguage: ko.PureComputed<string> = ko.pureComputed(() => global.Logonme.SelectedLanguage());

  constructor(params: { lm: AuthenticatedLocalizationManager, app: App }) {
    this.app = params.app ?? new App();
    this.lm = params.lm;
    this.updateStrings(this.selectedLanguage())
    this.selectedLanguage.subscribe((lang: string) => {
      this.updateStrings(lang)
    })
  }

  private updateStrings(lang: string) {
    if (this.lm == null) 
      return;
    var defaultLang = "default";
    this.id(this.app.id ?? "");
    this.name(this.lm.getAppProperty(this.app, "linkName", this.app.name, lang, defaultLang));
    this.description(this.lm.getAppProperty(this.app, "linkDescription", this.app.description, lang, defaultLang));
    this.tooltip(this.lm.getAppProperty(this.app, "linkTooltip", "", lang, defaultLang));
    this.linkUri(this.lm.getAppProperty(this.app, "linkUri", "", lang, defaultLang));
    this.iconUri(this.lm.getAppProperty(this.app, "iconUri", "", lang, defaultLang));
  }
}

export default { viewModel: Application, template: template };
