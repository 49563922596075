import * as ko from 'knockout';

import { AuthMethod } from '../contracts';
import template from './AuthLink.html';

class AuthLink {
  
  imageUri : ko.Observable<string> = ko.observable<string>("");
  imageAlt : ko.Observable<string> = ko.observable<string>("");
  hasImage: ko.PureComputed<boolean> = ko.pureComputed(() => this.imageUri() != null && this.imageUri() != '');
  displayName: ko.Observable<string> = ko.observable<string>("")

  constructor(params: { method: AuthMethod }) {
    var lang = global.Logonme.SelectedLanguage();
    var method = params.method ?? new AuthMethod();

    this.displayName(global.Logonme.LocalizationManager.getPropertyX(method, [ 'displayName' ], null, lang));

    var relativeUri = global.Logonme.LocalizationManager.getPropertyX(method, [ 'imageUrl', 'uri' ], null, lang);
    if (relativeUri) {
      // TODO: fix this
      var uri = `../wasm/${relativeUri}`;
      this.imageUri(uri);
    }
    this.imageAlt(global.Logonme.LocalizationManager.getPropertyX(method, [ 'imageUrl', 'text' ], null, lang))
  }
}

export default { viewModel: AuthLink, template: template };
