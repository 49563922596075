import * as ko from "knockout";
import template from './ContentHeader.html';
import { Country } from "localization";

class ContentHeader {

  private defaultLang: Country = global.Logonme.LocalizationManager.getLocalizations()[1]
  languages: ko.ObservableArray<Country> = ko.observableArray()
  selectLanguageText: ko.Observable<string> = ko.observable<string>("");
  selectedLanguage: ko.PureComputed<string> = ko.pureComputed(() => global.Logonme.SelectedLanguage());

  constructor() {

    this.updateStrings(this.selectedLanguage());
    this.selectedLanguage.subscribe((lang :string) => {
      this.updateStrings(lang)
    })

    var localizations = global.Logonme.LocalizationManager.getLocalizations()
    for (var i = 0; i < localizations.length; i++) {
      this.languages.push(localizations[i]);
    }

  }

  private updateStrings(lang: string) {
    this.selectLanguageText(global.Logonme.LocalizationManager.getText('header-select-language', lang));
  }

  isSelectedLanguage(val: Country) : boolean {
    var isSelectedLanguage = this.selectedLanguage() == val.langCode;
    return isSelectedLanguage;
  }

  async selectLanguage(val: Country) {
    global.Logonme.SelectedLanguage(val.langCode);
  }
}

export default { viewModel: ContentHeader, template: template };
