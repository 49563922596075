import * as ko from "knockout";
import { AuthMethod, AuthState, NullAuthMethod } from './contracts';
import { LocalizationManager } from './localization';
import { getCookie, setCookie } from 'typescript-cookie'

class LocalStorageManager {

    constructor() {
    }

    public getItem<T>(id: string, defaultValue: T | null): T | null {
        try {
            var jsonValue = localStorage.getItem(id);
            if (jsonValue != null)
                return JSON.parse(jsonValue);
        } catch {
            console.log(`Failed to get storage object for key ${id}`);
        }
        return defaultValue;
    }

    public removeItem(id: string) {
        try {
            localStorage.removeItem(id);
        } catch {
            console.log(`Failed to remove local storage object for key ${id}`);
        }
    }

    public setItem<T>(id: string, value: T): void {
        try {
            var jsonValue = JSON.stringify(value);
            localStorage.setItem(id, jsonValue);
        } catch {
            console.log(`Failed to set local storage object ${value} for key ${id}`);
        }
    }
}

class LogonmeConfiguration {

    public baseUrl: string;
    public tenant: string;
    public version: string;
    public startupMethod: string | null;
    public startupParams: string | null;
    public externalConsent: boolean;

    constructor(currentUrl: string) {
        var url = new URL(currentUrl);
        this.baseUrl = url.port ? `${url.protocol}//${url.hostname}:${url.port}` : `${url.protocol}//${url.hostname}`;
        this.tenant = url.searchParams.get("tenant") ?? "test";
        this.version = url.searchParams.get("version") ?? "n/a";
        this.startupParams = url.searchParams.get("startupParams");
        this.startupMethod = url.searchParams.get("method");
        this.externalConsent = url.searchParams.get("consent") ? true : false;
    }
}

enum ViewId {
    None,
    Methods,
    Auth,
    Home
}

class LogonmeGlobalData {

    public DebugLog: boolean = false;
    public LocalStorageManager: LocalStorageManager;
    public LocalizationManager: LocalizationManager;
    public Configuration: LogonmeConfiguration;
    public SelectedLanguage: ko.Observable<string>;
    public SelectedMethod: ko.Observable<AuthMethod | null>;
    public AuthState: ko.Observable<AuthState>;
    public ViewConstructedCount: ko.Observable<number>;
    public ActiveView: ko.Observable<ViewId>;
    public HasConsent: ko.Observable<boolean>;

    constructor(localizationManager: LocalizationManager, currentUrl: string) {
        this.LocalStorageManager = new LocalStorageManager();
        this.LocalizationManager = localizationManager;
        this.Configuration = new LogonmeConfiguration(currentUrl);
        this.SelectedLanguage = ko.observable<string>("default");
        this.SelectedMethod = ko.observable<AuthMethod | null>(null);
        this.AuthState = ko.observable<AuthState>(AuthState.Idle);
        this.ViewConstructedCount = ko.observable<number>(0);
        this.ActiveView = ko.observable<ViewId>(ViewId.None);
        this.HasConsent = ko.observable<boolean>(false);


        let hasConsent = hasMatchingCookieConsent(this.Configuration.tenant) || this.Configuration.externalConsent;
        if (hasConsent) {
            this.HasConsent(true)
        }

        this.SelectedLanguage.subscribe((lang: string) => {
            this.dump(`SelectedLanguage changed ${lang}`);
        });
        this.SelectedMethod.subscribe((method: AuthMethod | null) => {
            this.dump(`SelectedMethod changed ${method?.id}`);
            if (this.AuthState() != AuthState.PathComplete) {
                if (method == null) {
                    this.ActiveView(ViewId.Methods)
                } else {
                    this.ActiveView(ViewId.Auth);
                }
            }
        });
        this.AuthState.subscribe((authState: AuthState) => {
            this.dump(`AuthState changed ${authState}`);

            switch (authState) {
                case AuthState.PathComplete:
                    this.ActiveView(ViewId.Home);
                    break;

                case AuthState.PathPartialComplete:
                case AuthState.PathCancelled:
                case AuthState.LogoutCompleted:
                    this.ActiveView(ViewId.Methods);
                    break;

                case AuthState.PathFailed:
                    break;
    

            }
        });

        function hasMatchingCookieConsent(tenant: string) {
            var consentTenant : string = getCookie(".logonme-cookie-consent") ?? "";
            return tenant.localeCompare(consentTenant, undefined, { sensitivity: 'accent' }) === 0;
        }
    }

    dump(onChange: string) {
        if (!this.DebugLog)
            return;
        console.debug(onChange);
        console.debug(` => SelectedLanguage ${this.SelectedLanguage()}`);
        console.debug(` => SelectedMethod ${this.SelectedMethod()?.id}`);
        console.debug(` => ActiveView ${this.ActiveView()}`);
        console.debug(` => AuthState ${this.AuthState()}`);
    }


}

export { LogonmeConfiguration, LogonmeGlobalData, ViewId };



