import * as ko from 'knockout';
import fetch from 'node-fetch';

import { AuthMethod, AuthState, MethodsResponse } from '../contracts';
import template from './MethodList.html';
import { ViewId } from '../configuration';

class MethodList {

  title: ko.Observable<string> = ko.observable<string>("");
  subTitle: ko.Observable<string> = ko.observable<string>("");
  loginMethods: ko.ObservableArray<AuthMethod> = ko.observableArray<AuthMethod>([]);
  selectedLanguage: ko.PureComputed<string> = ko.pureComputed(() => global.Logonme.SelectedLanguage());
  activateView: ko.PureComputed<boolean> = ko.pureComputed(() => global.Logonme.ActiveView() == ViewId.Methods);

  constructor() {
    console.log("MethodList:constructor");

    this.updateStrings(this.selectedLanguage())
    this.selectedLanguage.subscribe((lang: string) => {
      this.updateStrings(lang)
      this.doGetMethods();
    })

    this.activateView.subscribe((activate: boolean) => {
      if (activate) {
        console.log("MethodList:activate");
        this.doGetMethods();
      }
    });

    global.Logonme.ViewConstructedCount(global.Logonme.ViewConstructedCount() + 1);
  }

  private updateStrings(lang: string) {
    this.title(global.Logonme.LocalizationManager.getText("methods-list-title", lang));
    this.subTitle(global.Logonme.LocalizationManager.getText("methods-list-title-message", lang));
  }

  async doGetMethods() {

    console.log("doGetMethods")
    var response = await this.getMethods();

    if (response.authState == AuthState.PathFailed) {
      response = await this.getMethods();
      // global.Logonme.SelectedMethod(null);
      global.Logonme.AuthState(response.authState);
      // return;
    }


    // only one method
    var sorted = response.challengeMethods?.sort((a, b) => (a.sortOrder < b.sortOrder) ? -1 : 1);
    if (sorted?.length == 1) {
      global.Logonme.SelectedMethod(sorted[0]);
      return;
    }

    // startup
    var method = sorted?.find((m: AuthMethod) => m.id == global.Logonme.Configuration.startupMethod);
    if (method) {
        global.Logonme.Configuration.startupMethod = null;
        global.Logonme.SelectedMethod(method);
    } 

    this.loginMethods(sorted);
    global.Logonme.AuthState(response.authState);

    if (response.launchExternal && response.launchUri) {

      if (response.authState != AuthState.PathCompletePendingFederation)
          global.Logonme.LocalStorageManager.removeItem("last-login-method");

      var redirectUri = response.launchUri; // relative
      console.log(`methods: re-directing to uri ${redirectUri}`)
      window.location.href = redirectUri;
    }
  }

  async doSelectMethod(method: AuthMethod) {
    this.loginMethods([])
    global.Logonme.SelectedMethod(method);
  }

  private async getMethods(): Promise<MethodsResponse> {
    var uri = `${global.Logonme.Configuration.baseUrl}/ls/${global.Logonme.Configuration.tenant}/forms/methods`;
    var response = await fetch(uri);
    var methodsResponse = await response.json<MethodsResponse>();
    return methodsResponse;
  }
}

export default { viewModel: MethodList, template: template };
