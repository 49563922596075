class Fido2Manager {

  private coerceToBase64Url(thing: any): any {
    if (Array.isArray(thing)) {
      thing = Uint8Array.from(thing);
    }
    if (thing instanceof ArrayBuffer) {
      thing = new Uint8Array(thing);
    }
    if (thing instanceof Uint8Array) {
      var str = "";
      var len = thing.byteLength;

      for (var i = 0; i < len; i++) {
        str += String.fromCharCode(thing[i]);
      }
      thing = window.btoa(str);
    }
    if (typeof thing !== "string") {
      throw new Error("could not coerce to string");
    }
    thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");
    return thing;
  }

  private coerceToArrayBuffer(thing: any): any {
    if (typeof thing === "string") {
      thing = thing.replace(/-/g, "+").replace(/_/g, "/");
      var str = window.atob(thing);
      var bytes = new Uint8Array(str.length);
      for (var i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i);
      }
      thing = bytes;
    }

    if (Array.isArray(thing)) {
      thing = new Uint8Array(thing);
    }

    if (thing instanceof Uint8Array) {
      thing = thing.buffer;
    }

    if (!(thing instanceof ArrayBuffer)) {
      throw new TypeError("could not coerce to ArrayBuffer");
    }

    return thing;
  }


  public async handleFidoAttestation(fidoAttestationChallenge: string | null): Promise<string> {
    if (!fidoAttestationChallenge)
      throw new Error(`Fido2 attestation challenge invalid`);

    var makeCredentialOptions = JSON.parse(fidoAttestationChallenge);
    makeCredentialOptions.challenge = this.coerceToArrayBuffer(makeCredentialOptions.challenge);
    makeCredentialOptions.user.id = this.coerceToArrayBuffer(makeCredentialOptions.user.id);
    makeCredentialOptions.excludeCredentials = makeCredentialOptions.excludeCredentials.map((c: any) => {
      c.id = this.coerceToArrayBuffer(c.id);
      return c;
    });
    if (makeCredentialOptions.authenticatorSelection.authenticatorAttachment === null)
      makeCredentialOptions.authenticatorSelection.authenticatorAttachment = undefined;

    var promise = navigator.credentials.create({ publicKey: makeCredentialOptions });

    return promise.then((newCredential: any) => {
      var attestationObject = new Uint8Array(newCredential.response.attestationObject);
      var clientDataJson = new Uint8Array(newCredential.response.clientDataJSON);
      var rawId = new Uint8Array(newCredential.rawId);

      var data = {
        id: newCredential.id,
        rawId: this.coerceToBase64Url(rawId),
        type: newCredential.type,
        extensions: newCredential.getClientExtensionResults(),
        response: {
          AttestationObject: this.coerceToBase64Url(attestationObject),
          clientDataJson: this.coerceToBase64Url(clientDataJson)
        }
      };
      var response = JSON.stringify(data);
      return response;
    },
      (reason) => {
        throw new Error(`Failed to create Fido2 credentials using built-in in browser function: ${reason}`, reason);
      });
  };


  public async handleFidoLogin(fidoLoginChallenge: string | null): Promise<string> {
    if (!fidoLoginChallenge)
      throw new Error(`Fido2 login challenge invalid`);

    var makeAssertionOptions = JSON.parse(fidoLoginChallenge);
    var challenge = makeAssertionOptions.challenge.replace(/-/g, "+").replace(/_/g, "/");
    makeAssertionOptions.challenge = Uint8Array.from(atob(challenge), c => c.charCodeAt(0));

    makeAssertionOptions.allowCredentials.forEach(function (listItem: any) {
      var fixedId = listItem.id.replace(/\_/g, "/").replace(/\-/g, "+");
      listItem.id = Uint8Array.from(atob(fixedId), c => c.charCodeAt(0));
    });

    // ask browser for credentials (browser will ask connected authenticators)
    var promise = navigator.credentials.get({ publicKey: makeAssertionOptions });
    return promise.then((assertedCredential: any) => {
      var authData = new Uint8Array(assertedCredential.response.authenticatorData);
      var clientDataJson = new Uint8Array(assertedCredential.response.clientDataJSON);
      var rawId = new Uint8Array(assertedCredential.rawId);
      var sig = new Uint8Array(assertedCredential.response.signature);
      var data = {
        id: assertedCredential.id,
        rawId: this.coerceToBase64Url(rawId),
        type: assertedCredential.type,
        extensions: assertedCredential.getClientExtensionResults(),
        response: {
          authenticatorData: this.coerceToBase64Url(authData),
          clientDataJson: this.coerceToBase64Url(clientDataJson),
          signature: this.coerceToBase64Url(sig)
        }
      };
      var response = JSON.stringify(data);
      return response;
    },
      (reason) => {
        throw new Error(`Failed to get Fido2 credentials using built-in browser function: ${reason}`, reason);
      });
  };

}


export { Fido2Manager };
