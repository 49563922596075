import * as ko from 'knockout';

import { LinkSet, Link } from '../contracts';
import template from './FooterColumn.html';

class FooterColumn {
  
  uri : ko.Observable<string> = ko.observable<string>("");
  text : ko.Observable<string> = ko.observable<string>("");
  imageSrc : ko.Observable<string> = ko.observable<string>("");
  links : ko.ObservableArray<Link> = ko.observableArray<Link>();

  constructor(params: { columns: LinkSet }) {
    var lang = global.Logonme.SelectedLanguage();
    var header = params.columns.Header;
    var links = params.columns.Links;

    this.uri(header?.Uri);
    this.text(header?.Text);
    this.imageSrc(header?.ImageSrc ?? "");
    this.links(links);
  }
}

export default { viewModel: FooterColumn, template: template };
