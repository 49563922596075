import * as ko from "knockout";
import template from './PagePuff.html';

class PagePuff {

  title: ko.Observable<string> = ko.observable<string>("");
  text: ko.Observable<string> = ko.observable<string>("");
  selectedLanguage: ko.PureComputed<string> = ko.pureComputed(() => global.Logonme.SelectedLanguage());

  constructor() {

    this.updateStrings(this.selectedLanguage());
    this.selectedLanguage.subscribe((lang :string) => {
      this.updateStrings(lang)
    })
  }

  private updateStrings(lang: string) {
    this.title(global.Logonme.LocalizationManager.getText('puff-content-title', lang));
    this.text(global.Logonme.LocalizationManager.getText('puff-content-text', lang));
  }
}

export default { viewModel: PagePuff, template: template };
